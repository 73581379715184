import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Event } from '../../domain/Event'
import { environment } from '../../../environments/environment'

@Component({
    selector: 'app-event-card',
    templateUrl: './event-card.component.html',
})
export class EventCardComponent {

    @Input() event!: Event
    @Input() currencyCode!: string
    @Input() canSelect!: boolean
    @Output() eventSelected = new EventEmitter<void>()
    showMore: boolean = false
    characterLimit: number = 140

    selectEvent() {
        this.eventSelected.emit()
    }

    protected readonly environment = environment
}
