<div class="d-flex flex-column flex-sm-row gap-2 justify-content-between">
    <div class="vstack gap-2">
            <span class="fw-semibold mb-1">
                {{ event.displayName }}
            </span>
        <div class="d-flex flex-column flex-sm-row gap-3 justify-content-between">
            <div class="hstack gap-3 align-items-start">
                <img
                    *ngIf="event.imageId !== null"
                    [ngSrc]="environment.cdnUrl | thumbor : 'event-images/' + event.imageId : { width: 82, height: 82, aspectFit: false }"
                    class="rounded object-fit-cover"
                    width="82"
                    height="82"
                />
                <div class="vstack gap-1">
                    <div class="hstack gap-2">
                        <div class="vstack gap-2 flex-grow-1">
                            <span *ngIf="event.description" class="small lh-sm">
                                <span [innerHTML]="
                                    showMore
                                        ? event.description
                                        : (event.description | slice:0:characterLimit) + (event.description.length > characterLimit ? '...' : '')
                                ">
                                </span>
                                <button
                                    *ngIf="event.description.length > characterLimit"
                                    class="btn btn-sm btn-link p-0 ms-1 lh-base"
                                    (click)="showMore = !showMore"
                                >
                                    {{ showMore ? 'Read less' : 'Read more' }}
                                </button>
                            </span>
                            <span
                                *ngIf="event.cancellationChargeAmount || event.cancellationChargePartyAmount"
                                class="small lh-sm text-secondary"
                            >
                                    Cancellation charges apply
                                </span>
                            <span
                                *ngIf="event.depositAmount"
                                class="hstack gap-1 small lh-sm text-secondary"
                            >
                                    <img
                                        ngSrc="assets/tag/normal_small.svg"
                                        width="16"
                                        height="16"
                                    />
                                {{ event.depositAmount / 100 | currency: currencyCode }}
                                per person
                                </span>
                        </div>
                    </div>
                </div>
            </div>
            @if (canSelect && event.imageId !== null) {
                <div class="hstack gap-3 align-items-start">
                    <button
                        class="btn btn-outline-secondary"
                        (click)="selectEvent()"
                    >
                        Select
                    </button>
                </div>
            }
        </div>
    </div>
    @if (canSelect && event.imageId === null) {
        <div class="hstack gap-3">
            <button
                class="btn btn-outline-secondary"
                (click)="selectEvent()"
            >
                Select
            </button>
        </div>
    }
</div>
