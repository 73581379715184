import { BookingReason } from './BookingReason'
import { Event } from './Event'

export class BookingSlotOption {
    constructor(
        public partySize: number,
        public durationMinutes: number,
        public allTableCount: number,
        public reservableTableCount: number,
        public reasonId: string | null,
        public eventId: string | null,
        public cancellationChargeAmount: number | null,
        public cancellationChargeIsPerCover: boolean,
        public depositAmount: number | null,
        public depositAmountIsPerCover: boolean
    ) { }

    isReservableWithConfiguration(
        partySize: number,
        reason: BookingReason | null,
        event: Event | null
    ): boolean {
        const reasonIsNotReservable = reason !== null && this.reasonId !== reason.id
        if (reasonIsNotReservable) {
            return false
        }
        const eventIsNotReservable = event !== null && this.eventId !== event.id
        if (eventIsNotReservable) {
            return false
        }
        const standardBookingIsNotReservable = (reason === null && event === null)
            && (this.reasonId !== null || this.eventId !== null)
        if (standardBookingIsNotReservable) {
            return false
        }
        const isOfPartySize = partySize === this.partySize
        if (!isOfPartySize) {
            return false
        }
        const hasReservableTables = this.reservableTableCount > 0
        if (!hasReservableTables) {
            return false
        }
        return true
    }

    get depositChargeAmount(): number | null {
        if (this.depositAmount === null) {
            return null
        }
        if (this.depositAmountIsPerCover) {
            return this.depositAmount * this.partySize
        } else {
            return this.depositAmount
        }
    }
}
