import { NgbDate } from '@ng-bootstrap/ng-bootstrap'

export function adaptStringToDate(value: string): Date | undefined {
    const date = new Date(value)
    if (isNaN(date.getTime())) {
        return undefined
    }
    return date
}

export class Helper {
    static isIntegerInRange(
        number: number | null,
        rangeMinimum: number | null,
        rangeMaximum: number | null
    ): boolean {
        if (number === null) {
            return false
        }
        if (rangeMinimum === null) {
            return false
        }
        if (rangeMaximum === null) {
            return false
        }
        return number >= rangeMinimum && number <= rangeMaximum
    }

    static makeNgbDate(date: Date): NgbDate {
        return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
    }

    static makeDate(ngbDate: NgbDate): Date {
        return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day)
    }

    static makeLocalISOFormattedDateString(date: Date): string {
        let offsetMs = date.getTimezoneOffset() * 60 * 1000
        let msLocal = date.getTime() - offsetMs
        let dateLocal = new Date(msLocal)
        let iso = dateLocal.toISOString()
        let [isoLocal] = iso.split('T')
        return isoLocal
    }

    static startOfDay(day: Date = new Date()) {
        return new Date(
            day.getUTCFullYear(),
            day.getMonth(),
            day.getDate(),
            0,
            0,
            0
        )
    }

    static endOfDay(day: Date = new Date()) {
        return new Date(
            day.getFullYear(),
            day.getMonth(),
            day.getDate() + 1,
            0,
            0,
            -1
        )
    }

    static makeNumberOrNull(value: string | null | undefined): number | null {
        if (!value) {
            return null
        }
        let number = Number(value)
        if (isNaN(number)){
            return null
        }
        return number
    }

    static areDatesEqual(d1: Date, d2: Date) {
        return d1 && d2 && d1.getTime() === d2.getTime()
    }
}
